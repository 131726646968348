import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const VP_DEV_PATHS = {
  _: 'dev',
  pullrequest: 'pullrequest',
  esbUi: 'esb-ui',
  wiki: 'wiki',
  repos: 'repos',
  servicemanager: 'servicemanager'
};
const VP_DEV_ROUTES = {
  path: VP_DEV_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.vp-dev.routes.title',
    description: 'external.vp-dev.routes.desc',
    icon: 'zui-icon-procedure',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_dev,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: VP_DEV_PATHS.pullrequest,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.vp-dev.routes.pullrequest.title',
        description: 'external.vp-dev.routes.pullrequest.desc',
        icon: 'zui-icon-size-distribution',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_prs,
          required: []
        })
      }
    }, {
      path: VP_DEV_PATHS.wiki,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.vp-dev.routes.wiki.title',
        description: 'external.vp-dev.routes.wiki.desc',
        icon: 'zui-icon-file-file-report-overview',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_dev,
          required: ['wiki']
        })
      }
    }, {
      path: VP_DEV_PATHS.esbUi,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.vp-dev.routes.esbUi.title',
        description: 'external.vp-dev.routes.esbUi.desc',
        icon: 'zui-icon-alert-status-alert-status-circle',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_dev,
          required: ['esb-ui']
        })
      }
    }, {
      path: VP_DEV_PATHS.repos,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.vp-dev.routes.repos.title',
        description: 'external.vp-dev.routes.repos.desc',
        icon: 'zui-icon-select-from-list',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_repo_management,
          required: ['view']
        })
      }
    }, {
      path: VP_DEV_PATHS.servicemanager,
      data: {
        title: 'external.vp-dev.routes.servicemanager.title',
        description: 'external.vp-dev.routes.servicemanager.desc',
        icon: 'zui-icon-co-observer-co-observer-1d',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_dev,
          required: ['service-manager']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { VP_DEV_PATHS, VP_DEV_ROUTES };
