import { SystemLanguage } from '@zeiss/ng-vis-common/types';
const enGBExternal = {
  'vp-dev': {
    routes: {
      title: 'Development',
      desc: 'Tools for product owners and developers',
      pullrequest: {
        title: 'Features',
        desc: 'Overview of active or completed features or bug fixes'
      },
      wiki: {
        title: 'Wiki',
        desc: 'Overview of internal wiki articles'
      },
      esbUi: {
        title: 'ESB UI',
        desc: 'Overview of APIs, user details, users and groups'
      },
      repos: {
        title: 'DevOps Repositories',
        desc: 'Overview of DevOps repositories tracked by the Vision Portal'
      },
      servicemanager: {
        title: 'Service Manager',
        desc: 'Overview of all microservices and their status'
      }
    }
  }
};
const deDEExternal = {
  'vp-dev': {
    routes: {
      title: 'Development',
      desc: 'Werkzeuge für Product Owner und Entwickler',
      pullrequest: {
        title: 'Features',
        desc: 'Übersicht aktiver oder abgeschlossener Features oder Bugfixes'
      },
      esbUi: {
        title: 'ESB UI',
        desc: 'Übersicht über APIs, Benutzerdetails, Benutzer und Gruppen'
      },
      servicemanager: {
        title: 'Service Manager',
        desc: 'Übersicht aller Mikroservices und ihres Status'
      }
    }
  }
};
const esESExternal = {
  'vp-dev': {
    routes: {}
  }
};

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
const VP_DEV_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: enGBExternal,
  [SystemLanguage['de-DE']]: deDEExternal,
  [SystemLanguage['es-ES']]: esESExternal
};

/**
 * Generated bundle index. Do not edit.
 */

export { VP_DEV_TRANSLATIONS };
